import Logo from './images/circle.png'

export default function footer() {
    return (
        <>
            <div className='footerShow'>
                <div className="footerWrapper">
                    <div className='footerInner'>
                        <div className='row'>
                            <div className='col-12 col-sm-4'>
                                <div className='footerTxtLeft'>
                                    <p><strong>Siège social</strong></p>
                                    <p>199, rue de la Rotonde</p>
                                    <p>Suite 2407</p>
                                    <p>Verdun, Qc H3E 0C1</p>
                                    <p className='footerTxt'>
                                        &#xa9; ALGEGO 2023
                                    </p>
                                    <p>&#xa0;</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-4'>
                                <div id='footerLogo'>
                                    <a href='https://algego.com' target='_blank'>
                                        <img src={Logo} alt='Logo ALGEGO'></img>
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-4'>
                                <div className='footerTxtRight'>
                                    <p><strong>Bureaux</strong></p>
                                    <p>486, rue Sainte-Catherine O.</p>
                                    <p>Suite 200</p>
                                    <p>Montréal, QC H3B 1A6</p>
                                    <p>(514) 360-7711</p>
                                    <p>&#xa0;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footerHide'>
                <div className="footerWrapper">
                    <div className='footerInner'>
                        <div className='row'>
                            <div className='col-12 col-sm-4'>
                                <div className='footerTxtLeft'>
                                    <p><strong>Siège social</strong></p>
                                    <p>199, rue de la Rotonde</p>
                                    <p>Suite 2407</p>
                                    <p>Verdun, Qc H3E 0C1</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-4'>
                                <div className='footerTxtRight'>
                                    <p><strong>Bureaux</strong></p>
                                    <p>486, rue Sainte-Catherine O.</p>
                                    <p>Suite 200</p>
                                    <p>Montréal, QC H3B 1A6</p>
                                    <p>(514) 360-7711</p>
                                    <p>&#xa0;</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-4'>
                                <div id='footerLogo'>
                                    <a href='https://algego.com' target='_blank'>
                                        <img src={Logo} alt='Logo ALGEGO'></img>
                                    </a>
                                </div>
                                <div className='footerTxt'>
                                    &#xa9; ALGEGO 2023
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
